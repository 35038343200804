AFRAME.registerSystem('scene', {
  init: function () {
    this.sceneEls = [];
    this.preloadTextures = this.preloadTextures.bind(this);
    this.el.addEventListener('loaded', this.preloadTextures);
  },

  preloadTextures: function () {
    var self = this;
    var texturesSrc = [
      './assets/textures/frames/frame1.jpg',
      './assets/textures/frames/frame2.jpg',
      './assets/textures/frames/frame3.jpg',
      './assets/textures/frames/frame4.jpg',
      './assets/textures/frames/frame5.jpg',
      './assets/textures/frames/frame6.jpg',
      './assets/textures/frames/frame7.jpg',
      './assets/textures/frames/frame8.jpg',
      './assets/textures/frames/frame9.jpg',
      './assets/textures/frames/frame10.jpg'
    ];

    var sceneEls = this.sceneEls;
    var sceneEl;
    var sceneTextures;
    for (var i = 0; i < sceneEls.length; ++i) {
      sceneEl = sceneEls[i];
      sceneTextures = sceneEl.components[sceneEl.id].textures;
      for (var j = 0; j < sceneTextures.length; ++j) {
        texturesSrc.push(sceneTextures[j]);
      }
    }

    this.textures = {};
    this.numberTexturesPreloaded = 0;

    for (var i = 0; i < texturesSrc.length; ++i) {
      this.preloadTexture(texturesSrc[i], function () {
        if (self.numberTexturesPreloaded >= texturesSrc.length) {
          self.sceneTexturesPreloaded = true;
          self.el.emit('texturespreloaded');
        }
      });
    }
  },

  registerScene: function (sceneEl) {
    var sceneComponent;
    var keys = Object.keys(sceneEl.components);
    for (var i = 0; i < keys.length; ++i) {
      if (keys[i].startsWith('scene')) {
        sceneEl.id = keys[i];
        this.sceneEls.push(sceneEl);
      }
    }
  },

  preloadTexture: function (textureSrc, callback) {
    var self = this;
    var sceneEl = this.el.sceneEl;
    var materialSystem = sceneEl.systems.material;
    var textures = this.textures;
    if (textures[textureSrc]) {
      callback();
      return;
    }

    materialSystem.loadTexture(textureSrc, {src: textureSrc}, function (texture) {
      sceneEl.renderer.initTexture(texture);
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      texture.needsUpdate = true;
      textures[texture.image.getAttribute('src')] = texture;
      self.numberTexturesPreloaded++;
      callback();
    });
  },

  renderGradient: function (color1, color2, color3, context, material, textureSize) {
    var maxWidth = Math.sqrt(textureSize * textureSize + textureSize * textureSize) / 2;
    var angleInRad = THREE.MathUtils.degToRad(45);
    var gradient;

    context.clearRect(0, 0, textureSize, textureSize);
    gradient = context.createLinearGradient(
      textureSize / 2 + Math.cos(angleInRad) * maxWidth,
      textureSize / 2 + Math.sin(angleInRad) * maxWidth,
      textureSize / 2 - Math.cos(angleInRad) * maxWidth,
      textureSize / 2 - Math.sin(angleInRad) * maxWidth
    );
    gradient.addColorStop(0.0, color1);
    gradient.addColorStop(0.5, color2);
    gradient.addColorStop(1.0, color3);
    context.fillStyle = gradient;
    context.fillRect(0,0,textureSize,textureSize);
    
    material.map = new THREE.CanvasTexture(context.canvas);
    material.map.needsUpdate = true;
    material.needsUpdate = true;
  },

  initCanvasTextures: function (component) {
    var textureSize = component.textureSize = 256;

    component.roomCanvasContext = document.createElement('canvas').getContext('2d');
    component.roomCanvasContext.canvas.width = textureSize;
    component.roomCanvasContext.canvas.height = textureSize;

    component.frameCanvasContext = document.createElement('canvas').getContext('2d');
    component.frameCanvasContext.canvas.width = textureSize;
    component.frameCanvasContext.canvas.height = textureSize;
  },

  loadNodeTexture: function (node, src, textureId) {
    node.material[textureId] = this.textures[src];
    node.material[textureId].flipY = false;
    node.material[textureId].encoding = THREE.sRGBEncoding;
    node.material.needsUpdate = true;
  },

  setRoomStyleFromNFT: function (nftMaterial, component) {
    var self = this;
    // https://github.com/Vibrant-Colors/node-vibrant
    Vibrant.from(nftMaterial.map.image)
    .maxDimension(256)
    .getPalette().then(
      (palette) => {
        self.renderGradient(palette.Vibrant.hex, palette.Muted.hex, palette.DarkVibrant.hex, component.roomCanvasContext, component.roomMaterial, component.textureSize);

        // Invert Vibrant color
        const invertedVibrantHex = self.invertHex(palette.Vibrant.hex.substring(1));
        // https://github.com/c0bra/color-scheme-js
        // distance (default 0.5)
        // scheme ('mono', 'contrast', 'triade', 'tetrade', and 'analogic')
        // variation (pastel, soft, light, hard, pale)
        var schemeColors = new ColorScheme()
        .from_hex(invertedVibrantHex)
        .distance(0.5)
        .scheme('analogic')
        .variation('pastel')
        // .add_complement(true)
        .colors();

        // var schemeColors = new ColorScheme()
        // .from_hex(invertedVibrantHex)
        // .distance(0.7)
        // .scheme('analogic')
        // .variation('hard')
        // .colors();

        self.renderGradient('#' + schemeColors[0], '#' + schemeColors[4], '#' + schemeColors[8], component.frameCanvasContext, component.frameMaterial, component.textureSize);
      
        var skyEl = component.el.querySelector('.sky');
        
        if (skyEl) {
          skyEl.setAttribute('material', 'colorTop', '#' + schemeColors[8]);
          skyEl.setAttribute('material', 'colorBottom', '#' + schemeColors[0]);
        }

        this.el.sceneEl.setAttribute('fog', {
          type: 'exponential',
          near: 1,
          far: 2000,
          color: '#' + schemeColors[0],
          density: component.data.fogDensity
        });
      
      }
    );
  },

  onModelLoaded: function () {
    var materials = this.materials;
    var mesh = this.el.getObject3D('mesh');
    var material;
    var sceneSystem = this.el.sceneEl.systems.scene;

    mesh.traverse(function (node) {
      var materialName;
      if (!node.material) { return; }
      if (node.material.type !== 'MeshStandardMaterial') {
        node.material.fog = false;
        return;
      }

      materialName = node.material.name === 'frame' ? node.name : node.material.name;

      if(materials[node.material.name] && node.name.indexOf('canvas-') === -1) {
        material = materials[materialName];
      } else {
        material = new THREE.MeshBasicMaterial();
        materials[materialName] = material;
      }
      
      if(node.material.map) { material.map = node.material.map; }
      node.material = material;
    });
    if (!this.texturesPreloaded) { return; }
    sceneSystem.updateModel(this);
  },

  updateModel: function (component) {
    var self = this;
    var mesh = component.el.getObject3D('mesh');
    var data = component.data;
    if (!mesh) { return; }

    mesh.traverse(function (node) {
      var material;
      var data = component.data;

      if (!node.material || 
          node.material.type !== 'MeshBasicMaterial' ||
          node.material.fog === false) { 
        return; 
      }

      if (node.name.indexOf('frame-') !== -1 ||
          node.name.indexOf('canvas-') !== -1) {
        node.visible = false;
      }

      let lightMapSrc = './assets/textures/' + component.name  + '/lightmap-' + data.aspectRatio + '.jpg';
      self.loadNodeTexture(node, lightMapSrc, 'lightMap');

      // To prevent shadow artifacts and backface culling.
      node.material.side = THREE.FrontSide;
      var textureSrc;

      if (node.name === 'canvas-' + data.aspectRatio) {
        textureSrc = component.data.nftTextureSrc;
        node.visible = true;
        component.nftMaterial = node.material;
        self.loadNodeTexture(node, textureSrc, 'map');
      } else if (node.name === 'frame-' + data.aspectRatio) {
        if (component.name === 'scene4' || component.name === 'scene5') {
          textureSrc = './assets/textures/' +  component.name + '/frame' + data.frameStyle + '.jpg';
        } else {
          textureSrc = './assets/textures/frames/frame' + data.frameStyle + '.jpg';
        }
        component.frameMaterial = node.material;
        node.visible = true;
        if (!data.nftColors) { self.loadNodeTexture(node, textureSrc, 'map'); }
      } else  {
        textureSrc = './assets/textures/' + component.name + '/style' + data.roomStyle + '.jpg';
        component.roomMaterial = node.material;
        if (!data.nftColors) { self.loadNodeTexture(node, textureSrc, 'map'); }
      } 
    });

    if (data.nftColors) { 
      self.setRoomStyleFromNFT(component.nftMaterial, component);
    }
  },

  invertHex: function (hex) {
    return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
  }
});