var img = new Image();
var img2 = new Image();
var img3 = new Image();
var img4 = new Image();
var img5 = new Image();
var img6 = new Image();

img4.src = '/assets/images/loadingScreenBackground.jpg';
img.src = '/assets/images/framesBackground.jpg';
img2.src = '/assets/images/settingsBackground.jpg';
img3.src = '/assets/images/errorBackground.jpg';
img5.src = '/assets/images/homeBackgroundPortrait.jpg';
img6.src = '/assets/images/homeBackgroundLandscape.jpg';