AFRAME.registerSystem('firebase', {
  init: function () {
    this.initFirebase();
  },

  initFirebase: function () {
    var firebaseConfig = {
      apiKey: "AIzaSyCUhZlXeyGbrXkuTqQOYxk3lyREAYSFE58",
      authDomain: "artuego-c9370.firebaseapp.com",
      projectId: "artuego-c9370",
      storageBucket: "artuego-c9370.appspot.com",
      messagingSenderId: "73739186404",
      appId: "1:73739186404:web:3d723bc9a0e51e7550bcf1",
      measurementId: "G-41N4Z7FGD4",
      databaseURL: "https://artuego-c9370-default-rtdb.firebaseio.com/"
    };
    // Initialize Firebase.
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  },

  getUserInfo: function (userName, callback) {
    firebase.database().ref('users').orderByChild('userName').limitToFirst(1).equalTo(userName).on('value',function (snapshot) {
      var keys;
      var userInfo = {};
      if (snapshot.val() === null) {
        callback(null);
        return;
      }
      keys = Object.keys(snapshot.val());
      userInfo.walletAddress = snapshot.val()[keys[0]].wallet;
      callback(userInfo);
    }, function (error) {
      console.error(error);
    });
  }
});