AFRAME.registerComponent('wallet', {
  schema: {
    username: {default: ''},
    walletAddress: {default: '0xb88f61e6fbda83fbfffabe364112137480398018'},
    nftId: {default: ''},
    collectionId: {default: ''},
    limitNFTPerCollection: {default: -1}
  },

  specialCollections: [
    {name: 'Bored Ape Kennel Club', buttonClass: 'kennelclub', author: 'Yuga Labs'},
    {name: 'BullsOnTheBlock', buttonClass: 'bullsontheblock', author: 'Data Robusta'},
    {name: 'The Wicked Craniums', buttonClass: 'craniums', author: 'Reckless Labs'},
    {name: 'Art Blocks Curated', buttonClass: 'artblocks'},
    {name: 'Art Blocks Playground', buttonClass: 'artblocks'},
    {name: 'Art Blocks Factory', buttonClass: 'artblocks'},
    {name: 'Bored Ape Yacht Club', buttonClass: 'bayc', author: 'Yuga Labs'},
    {name: 'Bonsai by ZENFT', buttonClass: 'zenft', author: 'Zenft Garden Society'},
    {name: 'CryptoPunks', buttonClass: 'cryptopunks', author: 'Larva Labs'},
    {name: 'Meebits', buttonClass: 'meebits', author: 'Larva Labs'},
    {name: 'VeeFriends', buttonClass: 'veefriends', author: 'Veefriends, LLC'},
    {name: 'Gutter Cat Gang', buttonClass: 'guttercatgang', author: 'Gutter Labs'},
    {name: 'The Alien Boy', buttonClass: 'alienboy', author: 'Alien Boy'},
    {name: 'Sorare', buttonClass: 'sorare', author: 'Sorare'}
  ],

  init: function () {
    // Artificial max # of NFTs.
    this.maxNumberNFTs = 500;
    this.pendingRequests = 0;
    this.requestedAssets = 0;
    this.preloadedTexturesNumber = 0;
    this.fetchedAssets = 0;
    this.nftsOffset = 0;
    this.nftCollectionNFTNumber = {};
  },

  update: function () {
    var data = this.data;
    var walletValue = document.querySelector('.wallet-value');
    if (!data.username || this.fetchedAssets) { return; }
    walletValue.innerHTML = data.walletAddress;
    this.connectOpenSea();
  },

  connectOpenSea: function () {
    var walletAddress = this.data.walletAddress;
    var nftsPerRequest = 20;

    if (this.data.nftId) {
      this.requestNFTs(walletAddress, 0, 1);
      return;
    }

    for (var i = 0; i < (this.maxNumberNFTs - this.fetchedAssets); i+=nftsPerRequest) {
      this.requestNFTs(walletAddress, this.nftsOffset, 20);
      this.nftsOffset += nftsPerRequest;
    }
  },

  requestNFTs: function (walletAddress, offset, nftsNumber) {
    var self = this;
    var options = {
      method: 'GET',
      headers: {
        'X-API-KEY': 'c163fbe9ed104336a6ac5ec7bf705b56',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    var requestURL = 'https://api.opensea.io/api/v1/assets?order_direction=desc&offset=' + offset +'&limit=' + nftsNumber;
    var walletId = walletAddress;
    requestURL += '&owner=' + walletId;

    if (this.data.nftId) { requestURL += '&token_ids=' + this.data.nftId; }

    this.pendingRequests++;
    fetch(requestURL, options)
      .then(function (response) {
        response.json().then(function (data) {
          if (data.assets.length) {
            self.pendingRequests--;
            self.requestedAssets += data.assets.length;
            self.populateNFTList(data.assets);
          } else {
            self.pendingRequests--;
            if (self.requestedAssets === 0 && self.pendingRequests === 0) {
              self.el.sceneEl.setAttribute('router', 'section', 'no-nfts-found');
            } else {
              if (self.fetchedAssets &&
                 self.preloadedTexturesNumber === self.fetchedAssets) {
                self.finishPreload();
              }
            }
          }
        });
      })
      .catch(function(err) {
        console.error(err);
      });
  },

  showCollectionButton: function (collection) {
    var selectEl = document.querySelector('.collection-select');
    var optionEl;
    var collectionMenuEl = document.querySelector('.collection-menu');
    var specialCollection;
    var buttonEl;

    this.onCollectionSelect = this.onCollectionSelect.bind(this);
    selectEl.addEventListener('change', this.onCollectionSelect);
    collectionMenuEl.classList.remove('hidden');
    for (var i = 0; i < this.specialCollections.length; ++i) {
      specialCollection = this.specialCollections[i];
      if (specialCollection.name === collection) {
        buttonEl = document.querySelector('.' + specialCollection.buttonClass);
        buttonEl.classList.remove('hidden');
        buttonEl.setAttribute('data-link', collection);
        if (document.querySelector('.collection-select option[value="' + collection + '"]')) {
          continue;
        }
        optionEl = document.createElement('option');
        optionEl.innerHTML = collection;
        optionEl.setAttribute('value', collection);
        selectEl.appendChild(optionEl);
      }
    }

    // Sort select
    var sorted = Array.prototype.slice.call(selectEl.options).sort(function(a, b) {
      if(a.value < b.value) return -1;
      if(a.value > b.value) return 1;
      return 0;
    });

    selectEl.innerHTML = '';
    for(var i = 0; i < sorted.length; i++) { selectEl.add(sorted[i]); }
  },

  onCollectionSelect: function (evt) {
    var optionEl = evt.target.options[evt.target.selectedIndex];
    this.populateImageGrid(optionEl.value);
  },

  getCollectionAuthor: function (collection) {
    for (var i = 0; i < this.specialCollections.length; ++i) {
      specialCollection = this.specialCollections[i];
      if (specialCollection.name === collection) {
        return specialCollection.author;
      }
    }
  },

  showCollectionTitle: function (collection) {
    var collectionMenuEl = document.querySelector('.collection-menu');
    var collectionTitleEl = document.querySelector('.collection-title');
    var titleEl = document.querySelector('.collection-title .title');
    var iconEl = document.querySelector('.collection-title .icon');
    
    collectionMenuEl.classList.add('hidden');
    if (!collectionTitleEl.classList.contains('hidden')) { return; }
    collectionTitleEl.classList.remove('hidden');
    for (var i = 0; i < this.specialCollections.length; ++i) {
      specialCollection = this.specialCollections[i];
      if (specialCollection.name === collection) {
        titleEl.innerHTML = specialCollection.name;
        iconEl.classList.add(specialCollection.buttonClass);
      }
    }
  },

  populateNFTList: function (nfts) {
    var sceneTexturesPreloaded = this.el.sceneEl.systems.scene.sceneTexturesPreloaded;
    var self = this;
    if (sceneTexturesPreloaded) {
      this.preloadTextures(nfts);
    } else {
      this.el.sceneEl.addEventListener('texturespreloaded', function () {
        self.preloadTextures(nfts);
      });
    }
  },

  finishPreload: function () {
    var nftsInfo = this.nftsInfo;
    var username = this.data.username;
    var gridEl = document.querySelector('.nft-grid');
    var singleNFTEl = document.querySelector('.single-nft-view');
    var closeNFTButtonEl = document.querySelector('.close-nft-button');
    var switchModeEl = document.querySelector('.switch-button');
    var imgEl;
    var imgContainerEl;
    var infoEl;
    var linkEl;
    var infoTemplate = `
      <div class='nft-title'>Test Title</div>
      <div class='nft-collection'>Test Collection</div>
    `;
  
    if (this.walletConnected) { return; }

    this.walletConnected = true;
    this.shuffleNFTArray();
    this.el.setAttribute('router', 'section', username);
    this.el.emit('nftspreloaded');

    this.imgGridEls = [];

    closeNFTButtonEl.addEventListener('click', function () {
      document.documentElement.classList.add('enable-scroll');
      singleNFTEl.classList.add('hidden');
      switchModeEl.classList.remove('hidden');
      closeNFTButtonEl.classList.add('hidden');
    });

    // Populate HTML list.
    for (var i = 0; i < nftsInfo.length; ++i) {
      linkEl = document.createElement('a');
      linkEl.href = 'javascript:;';

      imgContainerEl = document.createElement('div');
      imgContainerEl.classList.add('nft-image');

      imgEl = document.createElement('img');
      imgEl.src = nftsInfo[i].url;
      // imgEl.width = 275;
      // imgEl.height = 275;
      imgContainerEl.appendChild(imgEl);

      infoEl = document.createElement('div');
      infoEl.classList.add('nft-info');
      infoEl.classList.add('hidden');
      infoEl.innerHTML = infoTemplate;
      infoEl.querySelector('.nft-title').innerHTML = nftsInfo[i].title;
      infoEl.querySelector('.nft-collection').innerHTML = nftsInfo[i].collection;

      imgContainerEl.setAttribute('collection', nftsInfo[i].collection);
      imgContainerEl.appendChild(infoEl);

      linkEl.appendChild(imgContainerEl);

      linkEl.addEventListener('mouseenter', (function () {
        var el = infoEl;
        return function () {
          el.classList.remove('hidden');
        }
      })());
      linkEl.addEventListener('mouseleave', (function () {
        var el = infoEl;
        return function () {
          el.classList.add('hidden');
        }
      })());

      linkEl.addEventListener('click', (function () {
        var el = gridEl;
        var url = nftsInfo[i].url;
        var title = nftsInfo[i].title;
        var collection = nftsInfo[i].collection;
        var titleEl = document.querySelector('.single-nft-view h1');
        var collectionEl = document.querySelector('.single-nft-view h2');
        return function () {
          document.documentElement.classList.remove('enable-scroll');
          singleNFTEl.classList.remove('hidden');
          singleNFTEl.querySelector('img').src = url;
          titleEl.innerHTML = title;
          collectionEl.innerHTML = collection;
          closeNFTButtonEl.classList.remove('hidden');
          switchModeEl.classList.add('hidden');
        }
      })());

      this.imgGridEls.push(linkEl);

      this.populateImageGrid();

    }
  },

  populateImageGrid: function (collectionFilter) {
    var imgEls = this.imgGridEls;
    var gridEl = document.querySelector('.nft-grid');
    gridEl.innerHTML = '';

    for (var i = 0; i < imgEls.length; ++i) {
      if (!collectionFilter || collectionFilter === 'all' ||
          (collectionFilter && imgEls[i].querySelector('.nft-image').getAttribute('collection') === collectionFilter)) {
        gridEl.appendChild(imgEls[i]);
      }
    }
  },

  shuffleNFTArray: function () {
    var array = this.nftsInfo;
    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  },

  preloadTextures: function (nfts) {
    var self = this;
    var sceneEl = this.el.sceneEl;
    var sceneSystem = sceneEl.systems.scene;
    var collectionId = this.data.collectionId;

    var nftsInfo = this.nftsInfo = this.nftsInfo || [];
    var limitNFTPerCollection = this.data.limitNFTPerCollection;

    var author;
    var collection;
    var nftsToBeFetched = [];

    for (var i = 0; i < nfts.length; ++i) {
      author = nfts[i]['creator'] && nfts[i]['creator']['user'] && nfts[i]['creator']['user']['username'];
      collection = nfts[i]['collection']['name'];
      if (limitNFTPerCollection) {
        if(this.nftCollectionNFTNumber[collection] &&
          this.nftCollectionNFTNumber[collection] === limitNFTPerCollection) {
          continue;
        }
      }

      var url = nfts[i]['image_url'] || nfts[i]['asset_contract']['image_url'];
      
      if (!url || collectionId && collectionId !== collection) { continue; }

      var newNFT = {
        id: nfts[i]['token_id'],
        name: nfts[i]['name'] || nfts[i]['token_id'],
        author: this.getCollectionAuthor(collection) || author,
        title: nfts[i]['name'] || nfts[i]['token_id'],
        collection: collection,
        url: url,
        openseaLink: nfts[i]['permalink']
      };  

      if (!this.data.collectionId) {
        this.showCollectionButton(collection);
      } else {
        this.showCollectionTitle(collection);
      }

      this.fetchedAssets++;

      nftsInfo.push(newNFT);
      nftsToBeFetched.push(newNFT);

      if (this.nftCollectionNFTNumber[collection] !== undefined) {
        this.nftCollectionNFTNumber[collection]++;
      } else {
        this.nftCollectionNFTNumber[collection] = 1;
      }
    }

    if (this.data.nftId) {
      if (this.fetchedAssets !== 1) { return; }
      sceneSystem.preloadTexture(nftsInfo[0].url, function () {
        self.finishPreload();
      });
      return;
    }

    if (self.preloadedTexturesNumber === self.fetchedAssets) {
      self.connectOpenSea();
      return;
    }

    for (var i = 0; i < nftsToBeFetched.length; ++i) {
      sceneSystem.preloadTexture(nftsToBeFetched[i].url, function () {
        self.preloadedTexturesNumber++;
        if (self.preloadedTexturesNumber === self.fetchedAssets) { self.finishPreload();}
      });
    }
  }
});