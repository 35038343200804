AFRAME.registerComponent('user', {
  init: function () {
    var self = this;
    this.onAddWalletClick = this.onAddWalletClick.bind(this);
    this.onReplaceWalletClick = this.onReplaceWalletClick.bind(this);
    this.onCreateAccountCLick = this.onCreateAccountCLick.bind(this);
    this.onLoginAccountClick = this.onLoginAccountClick.bind(this);
    this.onSignoutAccountClick = this.onSignoutAccountClick.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.onSignUpClick = this.onSignUpClick.bind(this);
    this.waitForEmailVerification = this.waitForEmailVerification.bind(this);
    this.checkUsernameAvailability = this.checkUsernameAvailability.bind(this);
    this.connectMetamask = this.connectMetamask.bind(this);
    this.showMetamaskInstructions = this.showMetamaskInstructions.bind(this);
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.updateLoggedUserUI = this.updateLoggedUserUI.bind(this);

    this.setupValidityMessages();

    var userNameInputEl = this.userNameInputEl = document.querySelector('#signupUsername');
    userNameInputEl.oninput = this.checkUsernameAvailability;

    var createAccountFormEl = document.querySelector('.signup-form');
    createAccountFormEl.onsubmit = this.onCreateAccountCLick;

    var connectWalletEl = document.querySelector('.simple.wallet');
    connectWalletEl.addEventListener('click', this.onSignUpClick);

    var loginAccountFormEl = document.querySelector('.signin-form');
    loginAccountFormEl.onsubmit = this.onLoginAccountClick;

    var signoutButtonEl = document.querySelector('.signout-button');
    signoutButtonEl.addEventListener('click', this.onSignoutAccountClick);

    var addWalletButtonEl = this.addWalletButtonEl = document.querySelector('#addWallet');
    var replaceWalletEl = this.replaceWalletEl = document.querySelector('.replace-wallet');

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', this.connectMetamask);
      addWalletButtonEl.addEventListener('click', this.onAddWalletClick);
      replaceWalletEl.addEventListener('click', this.onReplaceWalletClick);
    } else {
      addWalletButtonEl.innerHTML = replaceWalletEl.innerHTML = 'Install Metamask to connect wallet';
      addWalletButtonEl.addEventListener('click', this.showMetamaskInstructions);
      replaceWalletEl.addEventListener('click', this.showMetamaskInstructions);
    }

    var forgotPasswordEl = document.querySelector('.forgot-password-form');
    forgotPasswordEl.onsubmit = this.onResetPassword;
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);

    if (localStorage.getItem('artuego-logged-user')) {
      this.el.emit('userloggedin');
    }
    this.el.addEventListener('userspaceloaded', this.updateLoggedUserUI);
  },

  onAuthStateChanged: function (user) {
    var userLoggedIn = this.userLoggedIn = user && user.emailVerified;
    this.user = user;
    if (!userLoggedIn) {
      localStorage.removeItem('artuego-logged-user');
      return;
    }
    this.updateLoggedUserUI(user);
  },

  updateLoggedUserUI: function (user) {
    var username = user.displayName || user.detail.displayName;
    var userLoggedIn = this.userLoggedIn;
    var settingsButtonEl = document.querySelector('.user-menu .settings');
    var tooltiptextEl = document.querySelector('.user-menu .tooltiptext');
    var settingsUserName = document.querySelector('.spaceurl .username');
    var userSpaceEl = document.querySelector('.user-space-name');
    var userSettingsEl = document.querySelector('.user-settings-button');
    var urlUsername = window.location.pathname.split('/')[1];
    var loggedUsername = localStorage.getItem('artuego-logged-user');
    var uiEl = document.querySelector('.ui');
    var watermarkLogoButtonEl = document.querySelector('.watermark-logo-button');
    var menuComponent = this.el.sceneEl.components.menu;
    var maxUsernameLength = 20;

    if (username.length >= maxUsernameLength) {
      username = username.substring(0, maxUsernameLength) + '...';
    }

    userSpaceEl.innerHTML = username;
    settingsButtonEl.classList.add('hidden');
    tooltiptextEl.classList.remove('logged-user');
    watermarkLogoButtonEl.href = "javascript:;"
    if (!userLoggedIn || urlUsername !== loggedUsername) {
      if (loggedUsername) {
        watermarkLogoButtonEl.href = '/' + loggedUsername;
      } else {
        watermarkLogoButtonEl.href = '/';
      }
      return;
    }

    settingsUserName.innerHTML = username;
    settingsButtonEl.classList.remove('hidden');
    uiEl.classList.add('hidden');
    tooltiptextEl.classList.add('logged-user');
  },

  showMetamaskInstructions: function () {
    window.location = 'https://docs.ethhub.io/using-ethereum/ethereum-new-user-guide/';
  },

  onAddWalletClick: function () {
    this.connectMetamask();
  },

  onReplaceWalletClick: async function () {
    var database = firebase.database();
    var updates = {};
    await this.connectMetamask();
    updates['users/' + this.user.uid + '/wallet'] = this.userWallet;
    database.ref().update(updates, (error) => {
      if (error) {
        // The write failed...
      } else {
        // Data saved successfully!
      }
    });
  },

  connectMetamask: async function () {
    var accounts;
    var walletValueEl = document.querySelector('.wallet-value');
    this.userWallet = undefined;
    this.addWalletButtonEl.style.fontSize = '';
    this.addWalletButtonEl.innerHTML = 'Connect wallet with Metamask';
    this.replaceWalletEl.innerHTML = 'Replace Wallet';

    accounts = await ethereum.request({method: 'eth_requestAccounts'});

    this.userWallet = accounts[0];
    this.addWalletButtonEl.style.fontSize = '13px';
    this.addWalletButtonEl.innerHTML = this.userWallet;
    walletValueEl.innerHTML = this.userWallet;
  },

  checkUsernameAvailability: function () {
    this.checkUsernameDelay = 500;
  },

  showUsernameError: function (message) {
    var userNameInputEl = this.userNameInputEl;
    var inputHelpTextEl = document.querySelector('.usernameHelp');

    inputHelpTextEl.innerHTML = message;
    inputHelpTextEl.classList.add('error');
    inputHelpTextEl.classList.remove('ok');

    userNameInputEl.classList.add('error');
    userNameInputEl.classList.remove('ok');
    userNameInputEl.classList.add('user-taken');
    userNameInputEl.classList.remove('user-available');
  },

  checkUsername: function () {
    var self = this;
    var userNameInputEl = this.userNameInputEl;
    var inputHelpTextEl = document.querySelector('.usernameHelp');
    var inputUsername = userNameInputEl.value.toLowerCase();
    this.username = undefined;
    if (inputUsername.length === 0) {
      this.showUsernameError('Username cannot be empty');
      return;
    }

    if (inputUsername.length > 30) {
      this.showUsernameError('Username too long. Maximum is 30 characters');
      return;
    }

    if (/^(\w|\.|-)+$/.test(inputUsername) === false) {
      this.showUsernameError('Username must contain only letters, numbers, periods, and underscores');
      return;
    }

    if (inputUsername) {
      this.el.systems.firebase.getUserInfo(inputUsername, function (userInfo) {
        if (userInfo) {
          this.showUsernameError('Username is not available');
        } else {
          inputHelpTextEl.innerHTML = inputUsername + ' is available';
          inputHelpTextEl.classList.remove('error');
          inputHelpTextEl.classList.add('ok');

          userNameInputEl.classList.remove('error');
          userNameInputEl.classList.add('ok');
          userNameInputEl.classList.remove('user-taken');
          userNameInputEl.classList.add('user-available');

          self.username = inputUsername;
        }
      });
    } else {
      inputHelpTextEl.innerHTML = 'Pick a cool name for your personal space.';
      inputHelpTextEl.classList.remove('ok');
      inputHelpTextEl.classList.remove('error');
      userNameInputEl.classList.remove('ok');
      userNameInputEl.classList.remove('error');
      userNameInputEl.classList.remove('user-taken');
      userNameInputEl.classList.remove('user-available');
    }
  },

  tick: function (time, delta) {
    if (this.checkUsernameDelay > 0) {
      this.checkUsernameDelay -= delta;
      if (this.checkUsernameDelay <= 0) { this.checkUsername(); }
    }
  },

  onSignUpClick: function () {
    this.el.setAttribute('router', 'section' ,'signup');
  },

  onLoginClick: function () {
    this.el.setAttribute('router', 'section' ,'login');
  },

  onForgotPasswordClick: function () {
    this.el.setAttribute('router', 'section' ,'forgot-password');
  },

  setupValidityMessages: function () {
    var userName = document.querySelector('#signupUsername');
    var onInput = function onInput(e) {
      e.target.setCustomValidity('');
    };

    userName.oninvalid = function (e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity('A valid and available username is required');
      }
    };
    userName.oninput = onInput;

    var loginEmailEl = document.querySelector('#loginEmail');
    var signupEmailEl = document.querySelector('#signupEmail');
    var forgotPasswordEmailEl = document.querySelector('#forgotPasswordEmail');

    var onInvalidEmail = function onInvalidEmail(e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity('A valid email is required');
      }
    };

    signupEmailEl.oninvalid = onInvalidEmail;
    signupEmailEl.oninput = onInput;

    loginEmailEl.oninvalid = onInvalidEmail;
    loginEmailEl.oninput = onInput;

    forgotPasswordEmailEl.oninvalid = onInvalidEmail;
    forgotPasswordEmailEl.oninput = onInput;
  },

  onLoginAccountClick: function (evt) {
    var email = document.querySelector('#loginEmail').value;
    var password = document.querySelector('#loginPassword').value;
    var loginErrorEl = document.querySelector('.login-error');
    evt.preventDefault();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then((userCredential) => {
            var isEmailVerified = userCredential.user.emailVerified;
            var userName;
            if (!isEmailVerified) {
              loginErrorEl.classList.remove('hidden');
              loginErrorEl.innerHTML = 'Account not verified. Check your email to verify';
              return;
            }
            userName = firebase.auth().currentUser.displayName;
            localStorage.setItem('artuego-logged-user', userName);
            window.location = '/' + userName;
          })
          .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password' ||
                errorCode === 'auth/user-not-found') {
              loginErrorEl.classList.remove('hidden');
              loginErrorEl.innerHTML = 'Incorrect email or password.';
            }
          });
    });
  },

  onSignoutAccountClick: function () {
    var self = this;
    firebase.auth().signOut().then(function() {
      localStorage.removeItem('artuego-logged-user');
      self.el.setAttribute('router', 'section' , 'home');
    }, function(error) {
      console.log(error);
    });
  },

  onResetPassword: function (evt) {
    var self = this;
    var auth = firebase.auth();
    var email = document.querySelector('#forgotPasswordEmail').value;
    evt.preventDefault();
    auth.sendPasswordResetEmail(email).then(function() {
      self.el.setAttribute('router', 'section' , 'check-email');
      // Email sent.
    }).catch(function(error) {
      self.el.setAttribute('router', 'section' , 'check-email');
      // An error happened.
    });
  },

  onCreateAccountCLick: function (evt) {
    var userName = document.querySelector('#signupUsername').value;
    var email = document.querySelector('#signupEmail').value;
    var password = document.querySelector('#signupPassword').value;
    evt.preventDefault();
    if (!this.username) { return; }
    if (!this.userWallet) {
      document.querySelector('.addWalletHelp').classList.add('error');
      this.addWalletButtonEl.classList.add('error');
      return;
    }
    this.createUser(userName, email, password);
  },

  createUser: function (userName, email, password) {
    var self = this;
    var name = userName.toLowerCase();
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      var user = userCredential.user;
      var database = firebase.database();
      user.updateProfile({displayName: name});
      user.sendEmailVerification();
      database.ref('users/' + user.uid).set({
        wallet: this.userWallet,
        userName: name
      },  (error) => {
        if (error) {
          // The write failed...
        } else {
          // Data saved successfully!
        }
      });
      self.el.setAttribute('router', 'section' , 'confirm-account');

      this.waitForEmailVerification();

      return userCredential;
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      var loginEmailInputEl = document.querySelector('#signupEmail');
      var emailHelpEl = document.querySelector('.signup-form .emailHelp p');
      if (error.code === 'auth/email-already-in-use') {
        emailHelpEl.innerHTML = 'This email has been already used to create an account';
        emailHelpEl.style.color = 'red';
        loginEmailInputEl.classList.add('error');
      }
    });
  },

  waitForEmailVerification: function () {
    var self = this;
    firebase.auth().currentUser.reload().then(
      function () {
        if (firebase.auth().currentUser.emailVerified) {
          window.location = '/' + firebase.auth().currentUser.displayName;
        } else {
          setTimeout(self.waitForEmailVerification, 1000);
        }
    });
  }
});