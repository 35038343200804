AFRAME.registerComponent('scene5', {
  schema: {
    sceneModel: {default: ''},
    nftTextureSrc: {default: ''},
    frameStyle: {default: 1, min: 1, max: 2},
    roomStyle: {default: 1, min: 1, max: 2},
    aspectRatio: {default: 'squared', oneOf: ['squared', 'portrait', 'landscape']},
    nftColors: {default: true},
    fogDensity: {default: 0.034}
  },

  textures: [
    './assets/textures/scene5/frame1.jpg',
    './assets/textures/scene5/style1.jpg',
    './assets/textures/scene5/frame2.jpg',
    './assets/textures/scene5/style2.jpg',
    './assets/textures/scene5/lightmap-squared.jpg',
    './assets/textures/scene5/lightmap-portrait.jpg',
    './assets/textures/scene5/lightmap-landscape.jpg'
  ],

  cameraAnimations: [
    {
      initialPosition: new THREE.Vector3(0.0161, 1, -0.366),
      initialRotation: new THREE.Vector3(0, 0, 0),
      finalPosition: new THREE.Vector3(0.016, 1, -0.965),
      finalRotation: new THREE.Vector3(0, 0, 0),
      duration: 5000
    },
    {
      initialPosition: new THREE.Vector3(0.57, 1, -0.15),
      initialRotation: new THREE.Vector3(0.294, 0.023, 0),
      finalPosition: new THREE.Vector3(-0.021, 1, -0.13),
      finalRotation: new THREE.Vector3(0.294, 0.023, 0),
      duration: 5000
    }
  ],

  init: function () {
    var system = this.el.sceneEl.systems.scene;
    this.onModelLoaded = system.onModelLoaded.bind(this);
    this.onTexturesPreloaded = this.onTexturesPreloaded.bind(this);
    this.el.sceneEl.addEventListener('texturespreloaded', this.onTexturesPreloaded);
    this.el.addEventListener('model-loaded', this.onModelLoaded);
    this.el.setAttribute('gltf-model', this.data.sceneModel);
    system.registerScene(this.el);
    system.initCanvasTextures(this);

    this.materials = {};
  },

  update: function () {
    var system = this.el.sceneEl.systems.scene;
    system.updateModel(this);
  },

  onTexturesPreloaded: function () {
    this.texturesPreload
  }
});