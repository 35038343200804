require('./src/components/wallet.js');
require('./src/shaders/skyGradient.js');
require('./src/components/user.js');
require('./src/components/router.js');
require('./src/components/menu.js');
require('./src/vendor/vibrant.min.js');
window.ColorScheme = require('./src/vendor/color-scheme.min.js');
require('./src/vendor/GammaCorrectionShader.js');
require('./src/vendor/CopyShader.js');
require('./src/vendor/EffectComposer.js');
require('./src/vendor/EffectComposer.js');
require('./src/vendor/DigitalGlitch.js');
require('./src/vendor/GlitchPass.js');
require('./src/vendor/RenderPass.js');
require('./src/vendor/ShaderPass.js');
require('./src/vendor/EffectComposer.js');
require('./src/components/carousel.js');
require('./src/systems/scene.js');
require('./src/systems/firebase.js');
require('./src/components/scene1.js');
require('./src/components/scene2.js');
require('./src/components/scene3.js');
require('./src/components/scene4.js');
require('./src/components/scene5.js');
require('./src/preloadImages.js');
