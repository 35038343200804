AFRAME.registerComponent('router', {
  schema: {
    section: {default: 'home'}
  },

  init: function () {
    var userURL = window.location.toString().replace('/?/', '/');
    // Restore query parameters.
    userURL = userURL.replace('~search~','?');
    userURL = userURL.replace(/\~and\~/g, '&')
    history.replaceState('User Profile', 'User Profile', userURL);
    this.onPopstate = this.onPopstate.bind(this);
    this.onUserLoggedIn = this.onUserLoggedIn.bind(this);

    window.addEventListener('popstate', this.onPopstate);
    this.loadUserSpace();

    this.el.addEventListener('userloggedin', this.onUserLoggedIn);
  },

  update: function () {
    this.showSection();
  },

  onUserLoggedIn: function () {
    this.loadUserSpace();
  },

  onPopstate: function (evt) {
    if (!evt.state) { return; }
    this.el.setAttribute('router', 'section', evt.state.section);
  },

  loadUserSpace: function () {
    var self = this;
    var loggedUserName = localStorage.getItem('artuego-logged-user');
    var userName = window.location.pathname.split('/')[1] || loggedUserName;
    var section;
    if (!userName) { return; }
    section = document.querySelector("section." + userName);
    if (section !== null) {
      this.el.setAttribute('router', 'section', userName);
      return;
    }
    var urlParams = new URLSearchParams(window.location.search);
    var nftIdParam = urlParams.get('nftId');
    var collectionIdParam = urlParams.get('collectionId');

    if (userName === 'demo') {
      this.el.setAttribute('router', 'section', 'loading-user-space');
      this.el.setAttribute('wallet', {
        username: 'demo',
        limitNFTPerCollection: 20,
        nftId: nftIdParam,
        collectionId: collectionIdParam
      });
      return;
    }
    this.loadingUserSpace = true;
    this.el.setAttribute('router', 'section', 'loading-user-space');
    this.el.systems.firebase.getUserInfo(userName, function (userInfo) {
      if (!userInfo) {
        self.el.setAttribute('router', 'section', 'error-user-name');
        return;
      }
      self.el.setAttribute('wallet', {
        walletAddress: userInfo.walletAddress,
        username: userName,
        nftId: nftIdParam,
        collectionId: collectionIdParam
      });
      self.loadingUserSpace = false;
    });
  },

  showSection: function () {
    var sectionClass = this.data.section;
    var sectionEls = document.querySelectorAll('section');
    var currentSection = history.state && history.state.section;
    var section = document.querySelector("section." + sectionClass);
    var userLoggedIn = localStorage.getItem('artuego-logged-user');

    var url;

    if (section === null ||
        sectionClass === 'aframe' ||
        sectionClass === 'home' ||
        sectionClass === 'demo') {
      document.documentElement.classList.remove('enable-scroll');
    } else {
      document.documentElement.classList.add('enable-scroll');
    }

    if (userLoggedIn && (sectionClass === 'login' || sectionClass === 'signup')) {
      sectionClass = 'user-logged-in';
    }

    var urlParams = new URLSearchParams(window.location.search);

    if (currentSection !== sectionClass &&
        !this.loadingUserSpace &&
        sectionClass !== 'aframe') {
      var url = '/' + sectionClass;
      // Preserve query parameters.
      if (Array.from(urlParams).length) { url += '?' + urlParams.toString(); }
      history.pushState({section: sectionClass}, sectionClass, url);
    }

    if (sectionClass === 'demo' || !section) { sectionClass = 'aframe'; }
    for(var i = 0; i < sectionEls.length; ++i) {
      if (sectionEls[i].classList.contains(sectionClass)) {
        sectionEls[i].classList.remove('hidden');
        continue;
      }
      sectionEls[i].classList.add('hidden');
    }

    if (sectionClass === 'home') {
      history.replaceState({section: 'home'}, 'home', '/');
    }

    // User space loaded.
    if (!section) {
      this.el.emit('userspaceloaded', {displayName: this.data.section});
    }
  }
});