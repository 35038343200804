AFRAME.registerComponent('carousel', {
  schema: {
    sceneDuration: {default: 3000},
    transitionDuration: {default: 200}
  },

  init: function () {
    var self = this;
    var sceneEl = this.el.sceneEl;
    var composer = this.composer = new THREE.EffectComposer(sceneEl.renderer);
    var glitchPass = this.glitchPass = new THREE.GlitchPass();
    var renderPass = this.renderPass = new THREE.RenderPass(sceneEl.object3D, sceneEl.camera)
    var gammaCorrectionPass = new THREE.ShaderPass(THREE.GammaCorrectionShader);
    var backNFTEl = this.backNFTEl = document.querySelector('.navigation-arrow.back');
    var forwardNFTEl = this.forwardNFTEl = document.querySelector('.navigation-arrow.forward');
    var fullscreenButtonEl = this.fullscreenButtonEl = document.querySelector('.fullscreen-button');
    var playButtonEl = this.playButtonEl = document.querySelector('.play-button');
    var pauseButtonEl = this.pauseButtonEl = document.querySelector('.pause-button');
    var modeButtonEl = this.modeButtonEl = document.querySelector('.ui .switch-button');

    this.userMenuEl = document.querySelector('.user-menu');
    this.sceneEls = this.el.sceneEl.systems.scene.sceneEls;
    this.interpolatedVector = new THREE.Vector3();

    this.remainingSceneTime = this.data.sceneDuration;
    this.animationTime = 0;
    this.nftIndex = 0;

    composer.addPass(renderPass);
    composer.addPass(gammaCorrectionPass);
    composer.addPass(glitchPass);

    this.resize = this.resize.bind(this);
    this.render = this.render.bind(this);
    this.changeToRandomNFT = this.changeToRandomNFT.bind(this);
    this.onKeydown = this.onKeydown.bind(this);
    this.goToPreviousNFT = this.goToPreviousNFT.bind(this);
    this.gotToNextNFT = this.gotToNextNFT.bind(this);
    this.enterFullscreen = this.enterFullscreen.bind(this);
    this.onFullScreenChange = this.onFullScreenChange.bind(this);
    this.onPlayClicked = this.onPlayClicked.bind(this);
    this.onPauseClicked = this.onPauseClicked.bind(this);
    this.onUserInteraction = this.onUserInteraction.bind(this);

    this.originalRender = sceneEl.renderer.render;
    this.sceneTexturesLoaded = false;

    this.nftSequence = [];

    document.querySelector('body').addEventListener('mousemove', this.onUserInteraction); 
    document.querySelector('body').addEventListener('mouseover', this.onUserInteraction); 
    document.querySelector('body').addEventListener('keydown', this.onUserInteraction); 
    window.addEventListener('scroll', this.onUserInteraction);

    playButtonEl.addEventListener('click', this.onPlayClicked);
    pauseButtonEl.addEventListener('click', this.onPauseClicked);

    modeButtonEl.addEventListener('click', this.toggleMode);

    backNFTEl.addEventListener('click', this.goToPreviousNFT);
    forwardNFTEl.addEventListener('click', this.gotToNextNFT);

    if (AFRAME.utils.device.isIOS()) {
      fullscreenButtonEl.classList.add('hidden');
    } else {
      fullscreenButtonEl.addEventListener('click', this.enterFullscreen);
      document.addEventListener('fullscreenchange', this.onFullScreenChange);
    }
  
    window.addEventListener('resize', this.resize, false);
    window.addEventListener('keydown', this.onKeydown);
    sceneEl.addEventListener('nftspreloaded', function () {
      self.sceneTexturesLoaded = true;
      self.changeToRandomNFT();
    }, false);
    this.resize();
  },

  toggleMode: function () {
    var nftListEl = document.querySelector('.nft-list-view');
    var nftListBackgroundEl = document.querySelector('.nft-list-view-background');
    var is3D = document.querySelector('.nft-list-view-background').classList.contains('hidden');
    if (is3D) {
      document.body.classList.add('mode2d');
      nftListEl.classList.remove('hidden');
      nftListBackgroundEl.classList.remove('hidden');
      document.documentElement.classList.add('enable-scroll');
    } else {
      document.body.classList.remove('mode2d');
      nftListEl.classList.add('hidden');
      nftListBackgroundEl.classList.add('hidden');
      document.querySelector('.single-nft-view').classList.add('hidden');
      document.documentElement.classList.remove('enable-scroll');
    }
  },

  onUserInteraction: function () {
    this.lastUserInteractionTimer = 0;
    this.showUI();
  },

  showUI: function () {
    this.userMenuEl.classList.remove('hidden-animated');
    this.forwardNFTEl.classList.remove('hidden-animated');
    this.backNFTEl.classList.remove('hidden-animated');
  },

  hideUI: function () {
    this.userMenuEl.classList.add('hidden-animated');
    this.forwardNFTEl.classList.add('hidden-animated');
    this.backNFTEl.classList.add('hidden-animated');
  },  

  enterFullscreen: function () {
    var fullscreenContainerEl = document.querySelector('.fullscreen-container');
    var requestFullscreen =
    fullscreenContainerEl.requestFullscreen ||
    fullscreenContainerEl.webkitRequestFullscreen ||
    fullscreenContainerEl.mozRequestFullScreen ||  // The capitalized `S` is not a typo.
    fullscreenContainerEl.msRequestFullscreen;
    // Hide navigation buttons on Android.
    requestFullscreen.apply(fullscreenContainerEl, [{navigationUI: 'hide'}]);
    this.fullscreenButtonEl.classList.add('hidden');
  },

  onPlayClicked: function () {
    this.userPaused = false;
    this.playButtonEl.classList.add('hidden');
    this.pauseButtonEl.classList.remove('hidden');
  },

  onPauseClicked: function () {
    this.userPaused = true;
    this.playButtonEl.classList.remove('hidden');
    this.pauseButtonEl.classList.add('hidden');
  },

  onFullScreenChange: function () {
    var fullscreenEl =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    if (!fullscreenEl) { this.fullscreenButtonEl.classList.remove('hidden'); }
    document.activeElement.blur();
    document.body.focus();
  },

  gotToNextNFT: function () {
    var nftInfo;
    this.currentNFTIndex++;
    if (this.currentNFTIndex === this.nftSequence.length ||
        this.nftSequence.length === 0) {
      this.changeToRandomNFT();
      return;
    }

    nftInfo = this.nftSequence[this.currentNFTIndex];
    this.showNFT(
      nftInfo.nftIndex, nftInfo.sceneIndex,
      nftInfo.randomStyleIndex, nftInfo.randomFrameIndex,
      nftInfo.cameraAnimationIndex);
  },

  goToPreviousNFT: function () {
    if (this.currentNFTIndex === 0) { return; }
    this.currentNFTIndex--;
    nftInfo = this.nftSequence[this.currentNFTIndex];
    this.showNFT(
      nftInfo.nftIndex, nftInfo.sceneIndex,
      nftInfo.randomStyleIndex, nftInfo.randomFrameIndex,
      nftInfo.cameraAnimationIndex);
  },

  onKeydown: function (evt) {
    // space
    if (evt.keyCode === 32) { this.changeToRandomNFT(); }
    // right arrow
    if (evt.keyCode === 39) { this.gotToNextNFT(); }
    // left arrow
    if (evt.keyCode === 37) { this.goToPreviousNFT(); }
  },

  resize: function () {
    this.composer.setSize(window.innerWidth, window.innerHeight);
  },

  render: function (scene, camera) {
    var sceneEl = this.el.sceneEl;
    var renderer = sceneEl.renderer;
    renderer.render = this.originalRender;
    this.composer.render();
    renderer.render = this.render;
  },

  tick: function (time, delta) {
    var sceneEl = this.el.sceneEl;
    var renderer = sceneEl.renderer;
    var animationSpeed = 1500;
    var wallet = sceneEl.components.wallet;
    var cameraAnimationIndex;

    this.lastUserInteractionTimer += delta;
    if (this.lastUserInteractionTimer >= 3000) {
      this.hideUI();
    }

    if (!wallet.walletConnected || this.userPaused) { return; }

    if (this.remainingTransitionTime > 0) {
      this.remainingTransitionTime -= delta;
      this.glitchPass.goWild = true;
      sceneEl.renderer.render = this.render;
      if (this.remainingTransitionTime <= 0) {
        sceneEl.renderer.render = this.originalRender;
        this.glitchPass.goWild = false;
      }
      return;
    }

    this.animateCamera(delta);

    this.remainingSceneTime -= delta;
    if (this.remainingSceneTime <= 0) {
      this.remainingTransitionTime = this.data.transitionDuration;
      this.gotToNextNFT();
    }
  },

  animateCamera: function (delta) {
    var cameraEl = this.el.sceneEl.camera.el;
    var cameraAnimation = this.cameraAnimation;
    var fov = cameraAnimation.fov || 80;
    if (!cameraAnimation) { return; }
    var animationPoint = this.animationTime / cameraAnimation.duration;

    cameraEl.setAttribute('camera', 'fov', fov);

    if (this.animationTime >= cameraAnimation.duration) { return; }
    this.interpolatedVector.lerpVectors(
      cameraAnimation.initialPosition,
      cameraAnimation.finalPosition,
      animationPoint);
    cameraEl.object3D.position.copy(this.interpolatedVector);

    this.interpolatedVector.lerpVectors(
      cameraAnimation.initialRotation,
      cameraAnimation.finalRotation,
      animationPoint);
    cameraEl.setAttribute('rotation', this.interpolatedVector);
    this.animationTime += delta;
  },

  changeToRandomNFT: function () {
    var sceneEl = this.el.sceneEl;
    var wallet = sceneEl.components.wallet;
    var totalNFTs = wallet.nftsInfo && wallet.nftsInfo.length;
    var collectionId = sceneEl.getAttribute('wallet').collectionId;
    var nftIndex = this.nftIndex % totalNFTs;
    var cameraAnimationIndex;

    if (!totalNFTs) { return; }

    var sceneIndex = Math.floor(Math.random() * this.sceneEls.length);
    var cameraAnimationIndex;
    var getRandomInt = function (min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    this.nftIndex++;

    // prevent scene repeat.
    if (this.sceneEls.length > 1) {
      while (this.previousSceneIndex === sceneIndex) {
        sceneIndex = Math.floor(Math.random() * this.sceneEls.length);
      };
    }
    this.previousSceneIndex = sceneIndex;

    // prevent camera animation repeat.
    var newSceneEl = this.sceneEls[sceneIndex];
    var cameraAnimations = newSceneEl.components[newSceneEl.id].cameraAnimations;
    cameraAnimationIndex = Math.floor(Math.random() * cameraAnimations.length);
    if (cameraAnimations.length > 1) {
      while (cameraAnimationIndex === this.previousCameraAnimationIndex) {
        cameraAnimationIndex = Math.floor(Math.random() * cameraAnimations.length);
      }
    }
    this.previousCameraAnimationIndex = cameraAnimationIndex;

    var totalFrames = newSceneEl.components[newSceneEl.id].schema.frameStyle.max;
    var totalStyles = newSceneEl.components[newSceneEl.id].schema.roomStyle.max;
    var randomFrameIndex = getRandomInt(1, totalFrames);
    var randomStyleIndex = getRandomInt(1, totalStyles);

    this.nftSequence.push({
      nftIndex: nftIndex,
      sceneIndex: sceneIndex,
      styleIndex: randomStyleIndex,
      frameIndex: randomFrameIndex,
      cameraAnimationIndex: cameraAnimationIndex
    });
    this.currentNFTIndex = this.nftSequence.length - 1;

    this.showNFT(
      nftIndex, sceneIndex,
      randomStyleIndex, randomFrameIndex,
      cameraAnimationIndex);
  },

  showNFT: function (
    nftIndex, sceneIndex,
    styleIndex, frameIndex, cameraAnimationIndex) {
    var sceneEl = this.el.sceneEl;
    var wallet = sceneEl.components.wallet;
    var sceneSystem = sceneEl.systems.scene;
    var openseaButtonEl = document.querySelector('.opensea');

    var authorEl = document.querySelector('.nftinfo .author');
    var titleEl = document.querySelector('.nftinfo .title');
    var collectionEl = document.querySelector('.nftinfo .collection');

    if (!this.sceneTexturesLoaded) { return; }

    this.remainingSceneTime = this.data.sceneDuration;
    this.animationTime = 0;

    var currentNFT = this.currentNFT = wallet.nftsInfo[nftIndex];
    var nftTexture = sceneSystem.textures[currentNFT.url];
    var aspectRatio;
    var username = this.el.sceneEl.getAttribute('wallet').username;
    var minAspectRatioThreshold = 1.1;

    aspectRatio = 'squared';

    if ((nftTexture.image.width / nftTexture.image.height) > minAspectRatioThreshold) {
      aspectRatio = 'landscape';
    }

    if ((nftTexture.image.height / nftTexture.image.width) > minAspectRatioThreshold) {
      aspectRatio = 'portrait';
    }

    var nftInfo = wallet.nftsInfo[nftIndex];
    var affiliateLink = nftInfo.openseaLink + '?ref=0x90b035b88fddc9e429bb21b6e37d549869637bda';
    openseaButtonEl.href = affiliateLink;

    authorEl.innerHTML = nftInfo.author;
    titleEl.innerHTML = '"' + nftInfo.title + '"';
    collectionEl.innerHTML = nftInfo.collection;

    for (var i = 0; i < this.sceneEls.length; ++i) {
      this.sceneEls[i].object3D.visible = false;
    }

    var newSceneEl = this.sceneEls[sceneIndex];
    newSceneEl.object3D.visible = true;

    var cameraAnimation = newSceneEl.components[newSceneEl.id].cameraAnimations[cameraAnimationIndex];
    this.cameraAnimation = cameraAnimation;

    newSceneEl.setAttribute(newSceneEl.id, {
      nftTextureSrc: nftInfo.url,
      frameStyle: frameIndex,
      roomStyle: styleIndex,
      aspectRatio: aspectRatio
    });
    sceneSystem.updateModel(newSceneEl.components[newSceneEl.id]);
  }
});
