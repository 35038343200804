AFRAME.registerComponent('menu', {
  init: function () {
    var loginEl = document.querySelector('.menu .login');
    var signupEl = document.querySelector('.menu .signup');
    var createAccountEl = document.querySelector('.create-account a');
    var forgotPasswordEl = document.querySelector('.forgot-password-link a');
    var signinEl = document.querySelector('.signin-account a');
    var demoEl = document.querySelector('.demo');
    var collectionButtonEls = document.querySelectorAll('.icon.collection');
    var goHomeEls = document.querySelectorAll('.go-home');
    var userSettingsEl = document.querySelector('.icon.settings');
    var userSettingsButtonEl = document.querySelector('.user-settings-button');
    var userShareEl = this.userShareEl = document.querySelector('.watermark .icon.share');
    var nftShareEl = this.nftShareEl = document.querySelector('.nftinfo .icon.share');
    var logoButtonEls = document.querySelectorAll('.logo-button');
    var subscribeButtonEl = document.querySelector('.subscribe-button');
    var spaceurlEl = document.querySelector('.spaceurl');
    var self = this.el;

    this.startDemo = this.startDemo.bind(this);
    this.copyUserURL = this.copyUserURL.bind(this);
    this.copyNFTURL = this.copyNFTURL.bind(this);
    this.copyUserSpaceURL = this.copyUserSpaceURL.bind(this);

    demoEl.addEventListener('click', this.startDemo);

    for (var i = 0; i < logoButtonEls.length; ++i) {
      logoButtonEls[i].addEventListener('click', this.navigate('back'));
    }

    for (var i = 0; i < goHomeEls.length; ++i) {
      goHomeEls[i].addEventListener('click', function () { window.location = '/'; });
    }

    for (var i = 0; i < collectionButtonEls.length; ++i) {
      collectionButtonEls[i].addEventListener('click', function (evt) {
        var username = self.getAttribute('wallet').username;
        window.location = '/' + username + '?collectionId=' + evt.currentTarget.getAttribute('data-link'); 
      });
    }

    signinEl.addEventListener('click', this.navigate('login'));
    loginEl.addEventListener('click', this.navigate('login'));
    signupEl.addEventListener('click', this.navigate('signup'));
    userSettingsEl.addEventListener('click', this.navigate('user-settings'));
    userSettingsButtonEl.addEventListener('click', this.navigate('user-settings'));
    subscribeButtonEl.addEventListener('click', this.navigate('subscribe'));
    createAccountEl.addEventListener('click', this.navigate('signup'));
    forgotPasswordEl.addEventListener('click', this.navigate('forgot-password'));

    userShareEl.addEventListener('click', this.copyUserURL);
    nftShareEl.addEventListener('click', this.copyNFTURL);
    spaceurlEl.addEventListener('click', this.copyUserSpaceURL);
  },

  copyNFTURL: function () {
    var tooltipEl = document.querySelector('.nftinfo .tooltiptext');
    var username = this.el.getAttribute('wallet').username;
    var nftId = document.querySelector('[carousel]').components.carousel.currentNFT.id;
    var dummy = document.createElement('input');
    var urlParams = new URLSearchParams(window.location.search);
    var url;
    
    urlParams.set('nftId', nftId);
    url = window.location.href.split('?')[0] + '?' + urlParams.toString();

    document.body.appendChild(dummy);
    dummy.value = url;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    tooltipEl.innerHTML = 'Link Copied';

    setTimeout(function () {
      tooltipEl.innerHTML = 'Copy NFT Link';
    }, 500);
  },

  copyUserURL: function () {
    var tooltipEl = this.userShareEl.querySelector('.ui .tooltiptext');

    var dummy = document.createElement('input');
    var text = location.protocol + '//' + location.host + location.pathname;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    tooltipEl.innerHTML = 'Link Copied';
    setTimeout(function () { tooltipEl.innerHTML = 'Copy User Link'; }, 500);
  },

  copyUserSpaceURL: function () {
    var tooltipEl = document.querySelector('.spacelink .tooltiptext');
    var username = this.el.getAttribute('wallet').username;
    var dummy = document.createElement('input');
    var text = location.protocol + '//' + location.host + '/' + username;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    tooltipEl.innerHTML = 'Link Copied';
    setTimeout(function () {
      tooltipEl.innerHTML = 'Copy User Link';
    }, 500);
  },

  startDemo: function () {
    var self = this;
    var demoEl = document.querySelector('.demo');
    if (this.demoLoaded) {
      this.el.setAttribute('router', 'section', 'demo');
      return;
    }

    demoEl.innerHTML = '';
    demoEl.classList.add('button--loading');
    this.el.setAttribute('wallet', {
      username: 'demo',
      limitNFTPerCollection: 20
    });
    this.el.addEventListener('nftspreloaded', function () {
      self.demoLoaded = true;
      demoEl.innerHTML = 'VIEW DEMO';
      demoEl.classList.remove('button--loading');
    });
  },

  navigate: function(section) {
    var navigateTo = section;
    var self = this;
    return function () {
      if (section === 'back') {
        history.back();
        return;
      }
      self.el.setAttribute('router', 'section', navigateTo);
    }
  }
});